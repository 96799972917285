<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            銷售應用中心
          </b-breadcrumb-item>

          <b-breadcrumb-item :to="{ name: 'PreorderSettingList' }">
            預購單設定檔列表
          </b-breadcrumb-item>

          <b-breadcrumb-item active
            >{{
              isEditing ? '編輯預購單設定檔' : '新增預購單設定檔'
            }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Page Header End -->

    <!-- Form Start -->
    <div class="email-wrapper wrapper">
      <div class="row bg-white">
        <div class="col-md-2 mail-sidebar pt-3">
          <div class="menu-bar">
            <ul class="menu-items">
              <li :class="{ active: step == '', done: preorderSetting.id }" @click="goToStep('')">
                <a>基本設定</a>
                <i class="fa fa-check-circle icon-for-done" aria-hidden="true"></i>
                <i class="fa fa-check-circle-o icon-for-undone " aria-hidden="true"></i>
              </li>
              <li :class="{ active: step == '#fields', done: preorderSetting.config.fields && preorderSetting.config.fields.length > 0, disabled: !preorderSetting.id }" @click="goToStep('#fields')">
                <a>資料集欄位</a>
                <i class="fa fa-check-circle icon-for-done" aria-hidden="true"></i>
                <i class="fa fa-check-circle-o icon-for-undone " aria-hidden="true"></i>
              </li>
              <li :class="{ active: step == '#product-setting', disabled: !preorderSetting.id }" @click="goToStep('#product-setting')">
                <a>商品設定</a>
              </li>
              <li :class="{ active: step == '#advance-setting', disabled: !preorderSetting.id }" @click="goToStep('#advance-setting')">
                <a>進階設定</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-10 main-view">
          <b-overlay :show="showLoading">
            <b-card>
              <!-- 基本資料 section start -->
              <section v-if="step == ''">
                <!-- 欄位部分 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">{{ isEditing ? '編輯預購單設定檔' : '新增預購單設定檔' }}</h4>
                  <div class="col-12 col-xl-8">
                    <validation-error-alert
                      v-if="validationErrors"
                      :errors="validationErrors"
                    ></validation-error-alert>
                    <!-- 名稱 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="*預購單活動名稱"
                    >
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="text"
                        :state="v$.preorderSetting.title.$error ? false: null"
                        v-model="preorderSetting.title"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!v$.preorderSetting.title.$error">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 名稱 End -->

                    <!-- 開始時間 start_at -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="開始時間"
                      label-for="start_at"
                    >
                      <div class="row">
                        <div class="col-12 col-xl-6">
                          <datepicker
                            placeholder="Select Date"
                            v-model="start_at.date"
                            bootstrap-styling
                            format="yyyy-MM-dd"
                            :language="zh"
                            :input-class="v$.start_at.date.$error ? 'is-invalid' : ''"
                          ></datepicker>
                          <b-form-invalid-feedback :state="!v$.start_at.date.$error">
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>

                        <div class="col-12 col-xl-6">
                          <vue-timepicker
                            format="HH:mm"
                            v-model="start_at.time"
                            :input-class="[
                              'form-control'
                            ]"
                            :style="v$.start_at.time.HH.$error || v$.start_at.time.mm.$error ? 'border: 1px solid red' : ''"
                          ></vue-timepicker>
                          <b-form-invalid-feedback :state="!v$.start_at.time.HH.$error || !v$.start_at.time.mm.$error">
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </b-form-group>
                    <!-- 開始時間 end -->

                    <!-- 結束時間 end_at -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="結束時間"
                      label-for="end_at"
                    >
                      <div class="row">
                        <div class="col-12 col-xl-6">
                          <datepicker
                            placeholder="Select Date"
                            v-model="end_at.date"
                            bootstrap-styling
                            format="yyyy-MM-dd"
                            :language="zh"
                            :input-class="v$.end_at.date.$error ? 'is-invalid' : ''"
                          ></datepicker>
                          <b-form-invalid-feedback :state="!v$.end_at.date.$error">
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>

                        <div class="col-12 col-xl-6">
                          <vue-timepicker
                            format="HH:mm"
                            v-model="end_at.time"
                            :input-class="[
                              'form-control'
                            ]"
                            :style="v$.end_at.time.HH.$error || v$.end_at.time.mm.$error ? 'border: 1px solid red' : ''"
                          ></vue-timepicker>
                          <b-form-invalid-feedback :state="!v$.end_at.time.HH.$error || !v$.end_at.time.mm.$error">
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </b-form-group>
                    <!-- 結束時間 -->

                    <!-- 啟用 start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="是否啟用"
                    >
                      <b-form-checkbox v-model="preorderSetting.is_enabled">啟用</b-form-checkbox>
                    </b-form-group>
                    <!-- 啟用 end -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 基本資料 section end -->

              <!-- 欄位設定 section start -->
              <section v-if="step == '#fields'">
                <div>
                  <DynamicFormEditor
                    v-if="preorderSetting.id"
                    @output="saveFields"
                    editor-title="預約單欄位編輯"
                    :form-type="'form'"
                    :input-subjects="preorderSetting.config.fields"
                  ></DynamicFormEditor>
                </div>
              </section>
              <!-- 欄位設定 section end -->

              <!-- 商品設定 section start -->
              <section v-if="step == '#product-setting'">
                <!-- 標題 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">商品設定</h4>
                </div>
                <!-- 標題 End -->
                <!-- 商品清單 Start -->
                <!-- 商品清單 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 預約設定 section end -->

              <!-- 進階設定 section start -->
              <section v-if="step == '#advance-setting'">
                <!-- 欄位部分 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">進階設定</h4>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 進階設定 section end -->
            </b-card>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Form End -->

  </div>
</template>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import {
  required,
  //helpers
} from "@vuelidate/validators";
// import bookingApi from "@/apis/booking";
import preorderApi from "@/apis/preorder";
// import JsonEditor from '@/components/Dashboard/JsonEditor.vue';
import DynamicFormEditor from '@/components/DynamicFormEditor/DynamicFormEditor.vue';
// import TagsBox from "@/components/TagsBox";
import mergeDefaultAndDataModel from "@/utils/mergeDefaultAndDataModel";
import deepGet from "lodash/get";
// import { invoke } from "lodash";
import { mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import {format, getHours, getMinutes, set} from "date-fns";

export default {
  components: {
    // JsonEditor,
    DynamicFormEditor,
    // TagsBox,
    Datepicker,
    VueTimepicker,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      preorderSetting: {
        title: { required },
        config: {},
      },
      start_at: {
        date: { required },
        time: {
          HH: { required },
          mm: { required },
        },
      },
      end_at: {
        date: { required },
        time: {
          HH: { required },
          mm: { required },
        },
      },
    };
  },
  computed: {
    ...mapGetters("route", ["routeQuery"]),
    collectionFieldCandidates() {
      return [
        { text: '未設定', value: null },
        ...this.preorderSetting.config.fields.map(field => ({ text: deepGet(field, 'config.title'), value: deepGet(field, 'config._id') })),
      ]
    },
  },
  data() {
    return {
      zh,
      isEditing: false,
      showLoading: false,
      validationErrors: null,
      step: '',
      start_at: {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      },
      end_at: {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      },
      preorderSetting: {
        title: null,
        start_at: null,
        end_at: null,
        is_enabled: true,
        config: {
          fields: [],
        }
      },
    }
  },
  mounted() {
    if (this.$route.name === "PreorderSettingEdit") {
      this.isEditing = true;
      this.preorderSetting.id = this.$route.params.id;
      this.fetchPreorderSetting();
      this.navigateToStepFromCurrentRoute();
    } else {
      this.isEditing = false;
      this.$set(this, 'preorderSetting', this.mergeDefaultAndDataModel(this.preorderSetting, null));
    }
  },
  methods: {
    deepGet,
    mergeDefaultAndDataModel,
    presentValidationErrors(validationKey) {
      return deepGet(this.v$, validationKey + '.$errors', []).map(error => error.$message).join(', ')
    },
    cancel() {
      this.$router.push({ name: 'PreorderSettingList', query: this.routeQuery });
    },
    goToStep(step) {
      if (!this.preorderSetting.id) {
        return;
      }

      if (this.step == step) {
        return;
      }

      this.step = step
      if (this.$route.hash !== this.step) {
        this.$router.replace({ hash: this.step });
      }
    },
    navigateToStepFromCurrentRoute() {
      const anchor = this.$route.hash;
      if (anchor) {
        this.goToStep(anchor);
      }
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) return false;

      try {
        this.showLoading = true;

        this.formatDatetime("start_at");
        this.formatDatetime("end_at");

        // 編輯表單
        if (this.isEditing) {
          await preorderApi.updatePreorderSetting(this.preorderSetting.id, this.preorderSetting);
          this.$swal("儲存成功", "", "success");
        }
        // 新增表單
        else {
          let response = await preorderApi.createPreorderSetting({ ...this.preorderSetting});
          this.preorderSetting = response.data.data;
          this.$swal("新增成功", "", "success");
          this.isEditing = true;
          this.$router.replace({ name: "PreorderSettingEdit", params: { id: response.data.data.id } })
        }

        return true;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存失敗", "error");
        return false;
      } finally {
        this.showLoading = false;
      }
    },
    async fetchPreorderSetting() {
      if (! this.preorderSetting.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        this.showLoading = true;
        let response = await preorderApi.getPreorderSetting(this.preorderSetting.id);
        this.$set(this, 'preorderSetting', this.mergeDefaultAndDataModel(this.preorderSetting, response.data.data));

        const { start_at, end_at } = this.preorderSetting;

        // 還原 start_at & end_at 日期時間到 data
        if (start_at) {
          this.start_at = {
            date: new Date(start_at),
            time: {
              HH: String(getHours(new Date(start_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(start_at))).padStart(2, "0"),
            },
          };
        }
        if (end_at) {
          this.end_at = {
            date: new Date(end_at),
            time: {
              HH: String(getHours(new Date(end_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(end_at))).padStart(2, "0"),
            },
          };
        }
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    async saveFields(fields) {
      try {
        this.showLoading = true;

        this.preorderSetting.config.fields = fields;

        await preorderApi.updatePreorderSetting(this.preorderSetting.id, this.preorderSetting);
        this.$swal("儲存成功", "", "success");
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存資料錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    formatDatetime(key) {
      if (!this[key].date || !this[key].time.HH || !this[key].time.mm) return;

      this.preorderSetting[key] = format(
        set(new Date(this[key].date), {
          hours: Number(this[key].time.HH),
          minutes: Number(this[key].time.mm),
          seconds: 0,
        }),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
  }
}
</script>

<style lang="scss" scoped>
.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  .icon-for-done {
    display: none;
  }

  &.done {
    i {
      color: green;
    }

    .icon-for-done {
      display: unset;
    }

    .icon-for-undone {
      display: none;
    }
  }
}
</style>
